import React, {Component} from "react";
import Layout from "../Layout/Layout";
import Styles from './EditMeter.module.scss';
import Strings from "../../Utils/Strings";
import buttonStyle from "../../Components/Button/Button.module.scss";
import Button from "../../Components/Button";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import DropdownSelect from "../../Components/DropdownSelect";
import Api from "../../Api/Api";
import {USERS_PAGE_NUMBER, USERS_PAGE_SIZE} from "../../Utils/Constants";
import InputPhone from "../../Components/InputPhone/InputPhone";

class EditMeter extends Component {

  state = {
    deviceName: "",
    deviceNameHasError: false,

    deviceLocation: "",
    deviceLocationHasError: false,

    user: "",
    userHasError: false,

    dateOfInstallation: "",
    dateOfInstallationHasError: false,

    depthOfInstallation: "",
    depthOfInstallationHasError: false,

    periodPoll: "",
    periodPollHasError: false,

    periodOfSendingDate: "",
    periodOfSendingDateHasError: false,

    usersOptions: [],
    selectedUsers: [],
    selectedUsersHasError: false,
  }

  componentDidMount() {
    this.fillingFields();
    this.gettingUsersList();
  };

  fillingFields = () => {
    const {updateMeter} = this.props.store;
    const users = updateMeter.users.map(item => {
      return {id: item.id, label: item.username, value: item.id}
    });
    this.setState({
      deviceName: updateMeter.model || "",
      user: updateMeter.contact_full_name || "",
      selectedUsers: users,
      deviceLocation: updateMeter.meter_address || "",
      dateOfInstallation: updateMeter.installation_date || "",
      depthOfInstallation: updateMeter.sensor_depth_cm || "",
      periodPoll: updateMeter.sensor_read_period_min || "",
      periodOfSendingDate: updateMeter.gsm_wake_up_period_min/60 || "",
    });
  };
  gettingUsersList = () => {
    new Api(this.props.store)
      .usersList(USERS_PAGE_NUMBER, USERS_PAGE_SIZE)
      .then(({data}) => {
        let array = data.data.users.map(item => {
          return {id: item.id, label: item.username, value: item.id}
        });
        this.setState({usersOptions: array})
      });
  };
  onSelectUser = (newItems) => {
    this.setState({selectedUsers: newItems, selectedUsersHasError: false});
  };
  handleInputValue = (event, stateName, errorType) => {
    const {value} = event.target;
    if (stateName === "periodPoll" || stateName === "periodOfSendingDate") {
      this.setState({
        [stateName]: value.replace(/([^0-9])/g, ""),
        [errorType]: false
      });
    } 
    else if (stateName === "depthOfInstallation") {
      this.setState({
        [stateName]: value.replace(/(^[-+][0-9]+\.[0-9]+[eE][-+]?[0-9]+$)/g, ""),
        [errorType]: false
      });
    }
    else {
      this.setState({[stateName]: value, [errorType]: false});
    }

    
  };

  addNewMeter = () => {
    const {state} = this;
    const data = {
      id: this.props.store.updateMeter.id,
      model: state.deviceName,
      users_ids: state.selectedUsers ? state.selectedUsers.map(i => i.id) : [],
      contact_full_name: state.user,
      meter_address: state.deviceLocation,
      installation_date: state.dateOfInstallation,
      sensor_depth_cm: state.depthOfInstallation,
      sensor_read_period_min: state.periodPoll,
      gsm_wake_up_period_min: state.periodOfSendingDate*60,

    };

    let isError = false;
    for (let key in data) {
      // if (key === "deviceName") {
      //   if (data[key].length === 0) {
      //     isError = true;
      //     this.setState({deviceNameHasError: true});
      //   }
      // }
      // if (key === "deviceLocation") {
      //   if (data[key].length === 0) {
      //     isError = true;
      //     this.setState({deviceLocationHasError: true});
      //   }
      // }
      // if (key === "user") {
      //   if (data[key].length === 0) {
      //     isError = true;
      //     this.setState({userHasError: true});
      //   }
      // }
      // if (key === "dateOfInstallation") {
      //   if (data[key].length === 0) {
      //     isError = true;
      //     this.setState({dateOfInstallationHasError: true});
      //   }
      // }
      if (key === "depthOfInstallation") {
        if (data[key].length > 0 && (data[key] < 4 || data[key] > 14400)) {
          isError = true;
          this.setState({depthOfInstallationHasError: true});
        }
      }
      if (key === "periodPoll") {
        if (data[key].length > 0 && (data[key] < 4 || data[key] > 14400)) {
          isError = true;
          this.setState({periodPollHasError: true});
        }
      }
      if (key === "periodOfSendingDate") {
        if (data[key].length > 0 && (data[key] < 4 || data[key] > 14400)) {
          isError = true;
          this.setState({periodOfSendingDateHasError: true});
        }
      }
    }

    if (!isError) {
        new Api(this.props.store)
          .updateMeter(JSON.stringify(data))
          .then(response => {
            if(response.data.ok) {
              this.props.history.push("/tableList");
            } else {
              console.log(response);
            }
          });
    }
  };

  render() {
    const {handleInputValue, addNewMeter, onSelectUser} = this;
    const {
      deviceName, deviceNameHasError, deviceLocation, deviceLocationHasError, user, userHasError,
      dateOfInstallation, dateOfInstallationHasError, depthOfInstallation, depthOfInstallationHasError,
      periodPoll, periodPollHasError, periodOfSendingDate, periodOfSendingDateHasError,
      usersOptions, selectedUsers, selectedUsersHasError
    } = this.state;

    return (
      <Layout>
        <Link to="/tableList">
          <Button className={`${buttonStyle.btnXs} ${buttonStyle.btnBlue}`}>
            {Strings.buttons.back}</Button>
        </Link>

        <div className={Styles.addingItemPage}>

          <div className={Styles.item}>
            <span>{Strings.inputTitles.deviceName}</span>
            <input type="text"
                   placeholder={Strings.inputTitles.deviceName}
                   value={deviceName}
                   onChange={(event) => {
                     handleInputValue(event, "deviceName", "deviceNameHasError")
                   }}
            />
            {deviceNameHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}
          </div>

          <div className={Styles.item}>
            <span>{Strings.inputTitles.deviceLocation}</span>
            <input type="text"
                   placeholder={Strings.inputTitles.deviceLocation}
                   value={deviceLocation}
                   onChange={(event) => {
                     handleInputValue(event, "deviceLocation", "deviceLocationHasError")
                   }}
            />
            {deviceLocationHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}
          </div>

          <div className={Styles.item}>
            <span>{Strings.inputTitles.user}</span>
            <input type="text"
                   placeholder={Strings.inputTitles.user}
                   value={user}
                   onChange={(event) => {
                     handleInputValue(event, "user", "userHasError")
                   }}
            />
            {userHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}
          </div>

          <div className={Styles.item}>
            <span>{Strings.inputTitles.dateOfInstallation}</span>
            <input type="text"
                   placeholder={Strings.inputTitles.dateOfInstallation}
                   value={dateOfInstallation}
                   onChange={(event) => {
                     handleInputValue(event, "dateOfInstallation", "dateOfInstallationHasError")
                   }}
            />
            {dateOfInstallationHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}
          </div>

          <div className={Styles.item}>
            <span>{Strings.inputTitles.depthOfInstallation}</span>
            <input type="text"
                   placeholder={Strings.inputTitles.depthOfInstallation}
                   value={depthOfInstallation}
                   onChange={(event) => {
                     handleInputValue(event, "depthOfInstallation", "depthOfInstallationHasError")
                   }}
            />
            {depthOfInstallationHasError && <span className={Styles.hasError}>{Strings.errors.interval}</span>}
          </div>

          <div className={Styles.item}>
            <span>{Strings.inputTitles.periodPoll}</span>
            <input type="text"
                   placeholder={Strings.inputTitles.periodPoll}
                   value={periodPoll}
                   onChange={(event) => {
                     handleInputValue(event, "periodPoll", "periodPollHasError")
                   }}
            />
            {periodPollHasError && <span className={Styles.hasError}>{Strings.errors.interval}</span>}
          </div>

          <div className={Styles.item}>
            <span>{Strings.inputTitles.periodOfSendingDate}</span>
            <input type="text"
                   placeholder={Strings.inputTitles.periodOfSendingDate}
                   value={periodOfSendingDate}
                   onChange={(event) => {
                     handleInputValue(event, "periodOfSendingDate", "periodOfSendingDateHasError")
                   }}
            />
            {periodOfSendingDateHasError && <span className={Styles.hasError}>{Strings.errors.interval}</span>}
          </div>

          <div className={Styles.select}>
            <span>{Strings.inputTitles.users}</span>
            <DropdownSelect
              options={usersOptions}
              value={selectedUsers}
              onChange={onSelectUser}/>
            {selectedUsersHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}
          </div>

          <div className={Styles.item}>
            <Button className={`${buttonStyle.btnMax} ${buttonStyle.btnBlue}`}
                    onClick={addNewMeter}>
              {Strings.buttons.save}
            </Button>
          </div>
        </div>

      </Layout>
    )
  }
}

const mapStateToProps = (store) => {
  return {store};
};

export default connect(mapStateToProps)(EditMeter);