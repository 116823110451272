import React, {Component} from "react";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";

import './GlobalStyles.module.scss';
import SignIn from "../../Pages/SignIn/SignIn";

import Meter from "../../Pages/Meter/Meter";
import TableList from "../../Pages/TableList/TableList";

import '../../style.css';
import AddingNewMeter from "../../Pages/AddingNewMeter/AddingNewMeter";
import EditMeter from "../../Pages/EditMeter/EditMeter";
import Users from "../../Pages/Users/Users";

class App extends Component {

  render() {

    return (
      <Router>
        <Switch>
          <Route path="/" component={SignIn} exact/>
          <Route path="/tableList" component={TableList} exact/>
          <Route path="/users" component={Users} exact/>
          <Route path="/tableList/adding" component={AddingNewMeter} exact/>
          <Route path="/tableList/edit" component={EditMeter} exact/>
          <Route path="/tableList/meter/:id" component={Meter} exact/>
          <Redirect to='/'/>
        </Switch>
      </Router>
    );
  }
}

export default App;