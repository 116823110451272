import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

import Api from "../../Api/Api";
import actions from "../../Store/Actions/Actions";

import Button from "../../Components/Button";

import buttonStyle from '../../Components/Button/Button.module.scss';
import Styles from './SignIn.module.scss';
import Strings from "../../Utils/Strings";

class SignIn extends Component {

  state = {
    inpLogin: '',
    inpPassword: '',
    isErrorIncorrect: false,
    rememberMe: false
  };

  checkRememberMe = (e) => {
    if (e.target.type === 'checkbox') {
      this.setState({rememberMe: e.target.checked});
    }
  };

  handleLoginValue = (e) => this.setState({inpLogin: e.target.value});

  handlePasswordValue = (e) => this.setState({inpPassword: e.target.value});

  signIn = () => {
    this.setState({isErrorIncorrect: false});
    const body = {
      password: this.state.inpPassword,
      username: this.state.inpLogin
    };
    new Api()
      .signIn(JSON.stringify(body))
      .then(({data}) => {
        if (data.ok) {
          const {session: {access_token}, user: {role, first_name, last_name}} = data.data;
          this.props.onSIgn(access_token, role, first_name, last_name);
        } else {
          if (data.error.code === 4) {
            this.setState({isErrorIncorrect: true})
          }
        }
      })
  };

  checkKeyEnter = (e) => {
    if (e.keyCode === 13) {
      if (this.state.inpLogin !== '' && this.state.inpPassword !== '') {
        this.signIn();
      }
    }
  };

  render() {
    const {inpLogin, inpPassword, isErrorIncorrect} = this.state;
    const {handleLoginValue, handlePasswordValue, checkRememberMe, signIn, checkKeyEnter} = this;

    const error = isErrorIncorrect
      ? <div className={Styles.error}><span>{Strings.errors.signInError}</span></div> : null;

    if (this.props.store.access_token !== null) {
      return <Redirect to="/tableList"/>
    }

    return (
      <div className={Styles.container}>
        <div className={Styles.headerTitle}>
          <p>{Strings.loginPage.title}</p>
        </div>

        <div className={Styles.forms}>
          <input type="text"
                 placeholder={Strings.inputPlaceholders.login}
                 value={inpLogin}
                 onKeyUp={checkKeyEnter}
                 onChange={handleLoginValue}
          />
          <input type="password"
                 placeholder={Strings.inputPlaceholders.password}
                 value={inpPassword}
                 onKeyUp={checkKeyEnter}
                 onChange={handlePasswordValue}
          />
          {error}
          <label className={Styles.checkboxContainer} onClick={checkRememberMe}>
            <span className={Styles.text}>{Strings.loginPage.rememberMe}</span>
            <input type="checkbox"/>
            <span className={Styles.checkMark}/>
          </label>
          <Button className={`${buttonStyle.btnSign} ${buttonStyle.btnBlue}`} onClick={signIn}>
            {Strings.loginPage.signIn}
          </Button>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (store) => ({store});
const mapDispatchToProps = (dispatch) => {
  return {
    onSIgn: (token, role, first_name, last_name) => dispatch(actions.onSign(token, role, first_name, last_name))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);