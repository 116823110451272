// SIGNING
export const ON_SIGN = "ON_SIGN";

// UPDATING
export const UPDATE_MODERATOR = "UPDATE_MODERATOR";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_METER = "UPDATE_METER";

// LOGOUT
export const CLEAR_STORE = "CLEAR_STORE";

// WHEN SEARCHING METER
export const RESULT_OF_SEARCH = "RESULT_OF_SEARCH";
