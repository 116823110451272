import React, {Component} from "react";
import {connect} from "react-redux";
import ReactPaginate from "react-paginate";
import propTypes from "prop-types";

import Strings from "../../Utils/Strings";
import Styles from "./Pagination.module.scss";

class Pagination extends Component {

  static propTypes = {
    pageCount: propTypes.any,
    handlePageClick: propTypes.func.isRequired,
    forcePage: propTypes.any
  };

  render() {
    const {pageCount, handlePageClick, forcePage} = this.props;

    return (
      <ReactPaginate
        previousLabel={Strings.pagination.previous}
        nextLabel={Strings.pagination.next}
        breakLabel={"..."}
        breakClassName={'break-me'}
        pageCount={pageCount}
        forcePage={forcePage}
        onPageChange={handlePageClick}
        containerClassName={Styles.pagination}
        activeClassName={Styles.active}
      />
    )
  }
}

const mapStateToProps = store => ({store});

export default connect(mapStateToProps)(Pagination);