import React, {Component} from "react";

class Button extends Component {
  render() {
    const {onClick = (() => {}), className, children, isDisable} = this.props;
    return (
        <button
          className={className}
          onClick={(...params) => onClick(...params)}
          disabled={isDisable}
        >
          {children}
        </button>
    )
  };
}

export default Button;