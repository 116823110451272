import React, {Component} from "react";
import Layout from "../Layout/Layout";
import buttonStyle from "../../Components/Button/Button.module.scss";
import Strings from "../../Utils/Strings";
import Button from "../../Components/Button";
import {Link} from "react-router-dom";
import Styles from './Meter.module.scss';
import TableStyles from '../TableList/TableList.module.scss';
import {connect} from "react-redux";
import Api from "../../Api/Api";
import {METER_DATA_PAGE_NUMBER, PAGE_SIZE} from "../../Utils/Constants";
import Pagination from "../../Components/Pagination/Pagination";
import Loader from "../../Components/Loader/Loader";
import moment from "moment";

class Meter extends Component {

  state = {
    isLoader: true,
    indicators: [],
    meterInfo: [],
    pageCount: null,
    forcePage: null,
  };

  handlePageClick = (data) => {
    const pageNumber = data.selected + 1;
    this.gettingMeterData(pageNumber);
  };

  componentDidMount() {
    this.gettingMeterData();
  };

  gettingMeterData = (value) => {
    this.setState({isLoader: true, indicators: []});
    const pageNumber = value || METER_DATA_PAGE_NUMBER;
    const {id} = this.props.match.params;
    new Api(this.props.store)
      .meterData(id, pageNumber, PAGE_SIZE)
      .then(({data}) => {
        const {page, offset, totalCount} = data.data.pagination;
        this.setState({
          isLoader: false,
          meterInfo: data.data.meter,
          indicators: data.data.indicators,
          pageCount: Math.ceil(totalCount / offset),
          forcePage: page - 1,
        });
      });
  };

  MeterList = () => {
    const {indicators, forcePage, meterInfo} = this.state;
    console.log(indicators, meterInfo)
    return indicators.map((item, index) => (
      <tbody className={TableStyles.noClick} key={item.id}>
      <tr>
        <td>{(forcePage * PAGE_SIZE) + (index + 1)}</td>
        <td>{item.last_volume_date && moment.unix(item.last_volume_date).format("DD.MM.YYYY HH:mm:ss")}</td>
        <td>{item.volume}</td>
        <td>{item.volume_2}</td>
        <td>{item.ec}</td>
        <td>{item.temperature}&#176;C</td>
        <td>{item.meter_battery}</td>
        <td>{item.signal_level ? item.signal_level+"dB" : null  }</td>
      </tr>
      </tbody>
    ));
  };

  render() {
    const {MeterList, handlePageClick} = this;
    const {meterInfo, indicators, pageCount, forcePage, isLoader} = this.state;
    return (
      <Layout>
        <div className={Styles.Button}>
          <Link to='/tableList'>
            <Button className={`${buttonStyle.btnXs} ${buttonStyle.btnBlue}`}>
              {Strings.buttons.back}</Button>
          </Link>
        </div>
        <div className={Styles.customerInfo}>
          <div>
            <span>{Strings.customersInfo.serialNumber}</span>
            <span>{indicators.length > 0 && indicators[0].serial_number}</span>
          </div>
          <div>
            <span>{Strings.customersInfo.deviceName}</span>
            <span>{meterInfo.model}</span>
          </div>
          <div>
            <span>{Strings.customersInfo.deviceLocation}</span>
            <span>{meterInfo.meter_address}</span>
          </div>
          <div>
            <span>{Strings.customersInfo.dateOfInstallation}</span>
            <span>{meterInfo.installation_date}</span>
          </div>
          <div>
            <span>{Strings.customersInfo.depthOfInstallation}</span>
            <span>{meterInfo.sensor_depth_cm}</span>
          </div>
        </div>
        <div className={TableStyles.table}>
          <table>
            <thead>
            <tr>
              <td>#</td>
              <th>{Strings.table.tableHeader.volumeDate}</th>
              <th>{Strings.table.tableHeader.volume}</th>
              
              <th>{Strings.table.tableHeader.volumeSecond}</th>
              <th>{Strings.table.tableHeader.ec}</th>
              <th>{Strings.table.tableHeader.temperature}</th>
              <th>{Strings.table.tableHeader.meterBattery}</th>
              <th>{Strings.table.tableHeader.signalLevel}</th>
            </tr>
            </thead>
            <MeterList/>
          </table>
        </div>

        <Loader isLoader={isLoader}/>

        <div>
          <Pagination pageCount={pageCount}
                      handlePageClick={handlePageClick}
                      forcePage={forcePage}/>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = (store) => {
  return {store}
};

export default connect(mapStateToProps)(Meter);