import React from "react";
import InputMask from "react-input-mask";
import propTypes from "prop-types";

import Styles from "./InputPhone.module.scss";

const inputPhone = ({title, onChange, value, errorText, hasError, placeholder, inputIcon}) => {
  const error = hasError ? errorText : null;
  return (
    <div className={Styles.inputPhoneContainer}>
      <span>{title}</span>
      <InputMask mask="+\9\98 (99) 999-99-99"
                 onChange={(e) => onChange(e.target.value)}
                 value={value}
                 placeholder={placeholder}
                 className={Styles.inputPhone} />
      <p className={Styles.error}>{error}</p>
    </div>
      )
};

inputPhone.propTypes = {
  title: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  value: propTypes.string.isRequired,
  errorText: propTypes.string,
  hasError: propTypes.bool,
  placeholder: propTypes.string,
  inputIcon: propTypes.string,
};

export default inputPhone;
