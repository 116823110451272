// USER ROLES
export const USER_ROLE_ADMIN = "administrator";
export const USER_ROLE_MODERATOR = "moderator";
export const USER_ROLE_USER = "user";

// PAGINATION
export const PAGE_SIZE = 50;
export const PAGE_NUMBER = 1;

// METERS PAGINATION
export const METER_PAGE_NUMBER = 1;
export const METER_PAGE_SIZE = 1000;

// MODERATORS
export const MODERATOR_PAGE_NUMBER = 1;
export const MODERATOR_PAGE_SIZE = 1000;

// USERS
export const USERS_PAGE_NUMBER = 1;
export const USERS_PAGE_SIZE = 1000;

// METER DATA
export const METER_DATA_PAGE_NUMBER = 1;
export const METER_DATA_PAGE_SIZE = 20;