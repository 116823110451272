import {USER_ROLE_ADMIN, USER_ROLE_MODERATOR, USER_ROLE_USER} from "./Constants";

export default function (storeRole, role) {
  switch (role) {
    case USER_ROLE_ADMIN: {
      return storeRole === USER_ROLE_ADMIN;
    }
    case USER_ROLE_MODERATOR: {
      return storeRole === USER_ROLE_MODERATOR || storeRole === USER_ROLE_ADMIN;
    }
    case USER_ROLE_USER: {
      return storeRole === USER_ROLE_USER;
    }
    default:
      return false;
  }
}