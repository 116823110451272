export default {
  loginPage: {
    title: "АСДМ SCADA",
    rememberMe: "Запомнить меня",
    signIn: "Войти",
  },
  customersInfo: {
    serialNumber: "Серийный номер",
    deviceName: "Наименование прибора:",
    accountNumber: "Лицевой счёт:",
    deviceLocation: "Место расположения прибора:",
    dateOfInstallation: "Дата установки прибора:",
    customerPhoneNumber: "Телефонный номер абонента:",
    depthOfInstallation: "Глубина установки прибора(м):",
  },
  companyName: "АСДМ SCADA",
  companyDescription: "",
  inputTitles: {
    deviceName: "Наименование  прибора",
    deviceLocation: "Место расположения прибора",
    user: "Ответственное лицо",
    dateOfInstallation: "Дата установки прибора",
    depthOfInstallation: "Глубина установки прибора(м)",
    periodPoll: "Период опроса(минут)",
    periodOfSendingDate: "Период отправки данных(час)",

    volume: "Уровня воды, м",
    meterBattery: "Заряд батареи прибора",
    installationDepth: "Глубина установки прибора",
    systemTime: "Системное время",
    serverSimCardNumber: "№ сим карты 1-го модема СЕРВЕРА",
    serverSimCardReserve: "№ сим карты 2-го модема резерв",
    accountNumber: "Период опроса",
    accountNumberHover: "в минутах",
    username: "Пользователь",
    firstName: "Имя",
    lastName: "Фамилия",
    customerAddress: "Локация",
    customerPhoneNumber: "Телефонный номер абонента",
    imeiNumber: "IMEI код",
    password: "Создать пароль",
    users: "Пользователи",
    phone: "Телефон"
  },
  inputPlaceholders: {
    login: "Логин",
    password: "Пароль"
  },
  errors: {
    signInError: "Логин или пароль неправильный",
    fieldIsEmpty: "поле не должно быть пустым",
    interval: "Интервал от 4 до 14400",
    usernameHasAlreadyTaken: "Имя пользователья уже занято",
    imeiHasNotExist: "Этот IMEI еще не зарегистрирован в систему",
    imeiHasAlreadyTaken: "Этот IMEI уже существует",
    searchResultNotFound: "По вашему запросу ничего не найдено"
  },
  pages: {
    dashboard: "Панель",
    tableList: "Приборы",
    users: "Пользователи"
  },
  buttons: {
    logOut: "Выйти",
    back: "Назад",
    confirm: "Да",
    refuse: "Нет",
    add: "Добавить",
    close: "Закрыть",
    save: "Сохранить",
    search: "Поиск",
    reset: "Сбросить"
  },
  table: {
    tableHeader: {
      customerFullName: "Название",
      volume: "Уровень, м",
      ec: "М, ГР/л",
      volumeSecond: "mH2O",
      temperature: "Температура",
      model: "Модель",
      region: "Область",
      district: "Район",
      address: "Адрес",
      status: "Статус",
      active: "Активный",
      inActive: "Неактивный",
      meterBattery: "Батарейка",
      modemBattery: "Батарейка",
      hasProblem: "Код ошибки",
      created: "Время создания",
      updated: "Время редактирования",
      serialNumber: "Серийный номер",
      errorCode: "Код ошибки",
      voltage: "Волть",
      hour: "Час",
      day: "День",
      month: "Месяц",
      year: "Год",
      userName: "Пользователь",
      firstName: "Имя",
      lastName: "Фамилия",
      phoneNumber: "Телефон",
      imei: "IMEI",
      signalLevel: "Уровень сигнала",
      lastVolumeDate: "Время",
      volumeDate: "Время",
      lastSeenAt: "Время отправленных информаций"
    }
  },
  popup: {
    popupHeaderTitles: {
      logOut: "Вы точно хотите выйти?",
      infoIsNotAvailable: "Информация не доступно",
      delete: "Вы точно хотите удалить?"
    },
    meterInfoIsNotAvailable: "Информации счётчика пока пусто",
    deleteMeter: "Все данные этого счётчика будут утерянны"
  },
  pagination: {
    previous: "Предыдущий",
    next: "Следующий"
  },
  resultOfSearch: "Результаты поиска"
};