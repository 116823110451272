import React from "react";
import {connect} from "react-redux";
import {slide as Menu} from 'react-burger-menu'
import './Burger.scss';
import Styles from "./Layout.module.scss";
import Strings from "../../Utils/Strings";
import {NavLink} from "react-router-dom";
import CheckingUserRole from "../../Utils/CheckingUserRole";
import {USER_ROLE_MODERATOR} from "../../Utils/Constants";

class BurgerMenu extends React.Component {


  LinkItem = ({to = "#", icon = "#", title = "NONE"}) => {
    return (
      <NavLink to={to} activeClassName="active">
        <div className={Styles.menuItemSideBar}>
          <span className={`${icon}`}/>
          <p>{title}</p>
        </div>
      </NavLink>
    );
  };

  render() {
    const {LinkItem} = this;
    return (
      <Menu>
        <div>
          <div className={Styles.sidebarHeader}>
            <div className={Styles.headerTitle}>
              <p>{Strings.companyName}</p>
              <p>{Strings.companyDescription}</p>
            </div>
            <hr/>
          </div>
          <LinkItem to="/tableList" icon="icon-truckList" title={Strings.pages.tableList}/>
          {CheckingUserRole(this.props.store.userRole, USER_ROLE_MODERATOR) &&
          <LinkItem to="/users" icon="icon-user" title={Strings.pages.users}/>}
        </div>
      </Menu>

    );
  }
}

const mapStateToProps = store => ({store});

export default connect(mapStateToProps)(BurgerMenu);