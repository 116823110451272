import React from "react";

import propTypes from "prop-types";
import LoaderReact from "react-loader-spinner";

import Styles from "./Loader.module.scss";

const Loader = ({isLoader}) => {
  return <LoaderReact
    className={Styles.loader}
    type="Rings"
    color="#5A8DEE"
    height={80}
    width={80}
    visible={isLoader}
  />
};

Loader.propTypes = {
  isLoader: propTypes.bool.isRequired
};

export default Loader;