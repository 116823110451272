import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter, NavLink} from 'react-router-dom';

import actions from "../../Store/Actions/Actions";

import BurgerMenu from "./BurgerMenu";
import Button from "../../Components/Button";
import Popup from "../../Components/Popup";

import Styles from './Layout.module.scss';
import PopupStyle from '../../Components/Popup/Popup.module.scss';
import buttonStyle from "../../Components/Button/Button.module.scss";
import Strings from '../../Utils/Strings';
import CheckingUserRole from "../../Utils/CheckingUserRole";
import {USER_ROLE_MODERATOR} from "../../Utils/Constants";
import Api from "../../Api/Api";

class Layout extends Component {

  state = {
    isPopup: false
  };

  componentDidMount() {
    if (this.props.store.access_token === null) this.props.history.push("/");
  };

  LinkItem = ({to = "#", icon = "#", title = "NONE"}) => {
    return (
      <NavLink to={to} activeClassName={Styles.active}>
        <div className={Styles.menuItem}>
          <span className={`${icon}`}/>
          <p>{title}</p>
        </div>
      </NavLink>
    );
  };

  openPopup = () => this.setState({isPopup: true});
  closePopup = () => this.setState({isPopup: false});

  logOut = () => {
    new Api(this.props.store).signOut().then(() => {});
    localStorage.clear();
    this.props.clearStore();
    this.props.history.push("/");
  };

  PopupComponent = () => {
    const {isPopup} = this.state;
    const {closePopup, logOut} = this;
    if (isPopup) {
      return (
        <Popup title={Strings.popup.popupHeaderTitles.logOut} closePopup={closePopup} className={PopupStyle.signIn}>
          <div className={Styles.logOutButtons}>
            <Button className={` ${buttonStyle.btnBlue}`} onClick={logOut}>
              {Strings.buttons.confirm}
            </Button>
            <Button className={` ${buttonStyle.btnDarkBlue}`} onClick={closePopup}>
              {Strings.buttons.refuse}
            </Button>
          </div>
        </Popup>
      )
    }
    return null;
  };

  render() {
    const {LinkItem, openPopup, PopupComponent} = this;
    const {firstName} = this.props.store;

    return (
      <div className={Styles.layout}>

        <PopupComponent/>

        <div className={Styles.sidebarContainer}>
          <div className={Styles.sidebarSection}>
            <div className={Styles.sidebarHeader}>
              <div className={Styles.headerTitle}>
                <p>{Strings.companyName}</p>
                <p>{Strings.companyDescription}</p>
              </div>
              <hr/>
            </div>

            <div className={Styles.sidebarMenu}>
              <LinkItem to="/tableList" icon="icon-truckList" title={Strings.pages.tableList}/>
              {CheckingUserRole(this.props.store.userRole, USER_ROLE_MODERATOR) &&
              <LinkItem to="/users" icon="icon-user" title={Strings.pages.users}/>}

            </div>

            <div className={Styles.sideBarFooter}>
              <Button className={`${buttonStyle.btnXs} ${buttonStyle.btnDarkBlue}`}
                      onClick={openPopup}>
                {Strings.buttons.logOut}
              </Button>
            </div>

          </div>
        </div>

        <div className={Styles.menuBurger}>
          <BurgerMenu/>
        </div>

        <div className={Styles.secondSection}>
          <div className={Styles.navBar}>
            <div className={Styles.companyName}>
              <span className='icon-user'/>
              <p>{firstName}</p>
            </div>
          </div>
          <div className={Styles.secondSectionContent}>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  };
}

const mapStateToProps = (store) => {
  return {store};
};

const mapDispatchToProps = () => (dispatch) => {
  return {
    clearStore: () => dispatch(actions.clearStore())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));