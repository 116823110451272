import React, {Component} from "react";
import Select from 'react-select';
import makeAnimated from "react-select/animated/dist/react-select.esm";

class DropdownSelect extends Component {

  render() {
    const {options, defaultState, isDisabled, value, onChange} = this.props;
    const animatedComponents = makeAnimated();
    const colourStyles = {
      control: (styles, {isDisabled}) => ({
        ...styles,
        width: '100%',
        minHeight: '2.5rem',
        backgroundColor: '#FFFFFF',
        border: '1px solid #B1B1B1',
        color: '#34495E',
        borderRadius: '0.2rem',
        outline: 'none',
        padding: '0 0 0 0.5rem',
        '&:hover': {
          border: '1px solid #B1B1B1',
        }
      }),
      clearIndicator: (styles) => ({
        ...styles,
        cursor: 'pointer',
        color: '#34495E',
        '&:hover': {
          color: '#34495E',
        }
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        cursor: 'pointer',
        color: '#B1B1B1',
        '&:hover': {
          color: '#B1B1B1',
        }
      }),
      input: (styles) => ({
        ...styles,
        outline: 'none',
        color: '#34495E',
      }),
      menu: (styles) => ({
        ...styles,
        backgroundColor: '#ffffff',
        border: '1px solid #B1B1B1'
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: '#34495E',
      }),
      multiValue: (styles) => ({
        ...styles,
        backgroundColor: '#F1F4F4',
      }),
      multiValueRemove: (styles) => ({
        ...styles,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#717F8C',
          color: '#34495E'
        }
      }),
      option: (styles, state) => ({
        ...styles,
        cursor: 'pointer',
        borderBottom: '1px solid #DFE3E7',
        color: '#34495E',
        backgroundColor: state.isSelected ? '#F1F4F4' : '',
        '&:hover': {
          backgroundColor: '#F1F4F4'
        }
      }),
      placeholder: (styles) => ({
        ...styles,
        color: '#B1B1B1',
        fontSize: '14px'
      })
    };
    return (
        <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            defaultValue={defaultState}
            value={value}
            isDisabled={isDisabled}
            onChange={onChange}
            isMulti
            styles={colourStyles}
            options={options}
        />
    )
  };
}

export  default  DropdownSelect;