import React, {Component} from "react";
import Layout from "../Layout/Layout";

import Styles from "./AddingNewMeter.module.scss";
import Strings from "../../Utils/Strings";
import buttonStyle from "../../Components/Button/Button.module.scss";
import Button from "../../Components/Button";
import {Link} from "react-router-dom";
import Api from "../../Api/Api";
import {connect} from "react-redux";
import {USERS_PAGE_NUMBER, USERS_PAGE_SIZE} from "../../Utils/Constants";

class AddingNewMeter extends Component {

  state = {
    accountNumber: "",
    accountNumberHasError: false,

    imeiNumber: "",
    imeiNumberHasError: false,
    imeiHasNotExist: false,
    imeiHasAlreadyTaken: false,

    usersOptions: [],
    selectedUsers: [],
    selectedUsersHasError: false,

    customerPhoneNumber: "",
    customerPhoneNumberHasError: false,

    customerAddress: "",
    customerAddressHasError: false,

    customerName: "",
    customerNameHasError: false,
  }

  componentDidMount() {
    this.gettingUsersList();
  };

  gettingUsersList = () => {
    new Api(this.props.store)
      .usersList(USERS_PAGE_NUMBER, USERS_PAGE_SIZE)
      .then(({data}) => {
        let array = data.data.users.map(item => {
          return {id: item.id, label: item.username, value: item.id}
        });
        this.setState({usersOptions: array})
      });
  };
  onSelectUser = (newItems) => {
    this.setState({selectedUsers: newItems, selectedUsersHasError: false});
  };
  handleInputValue = (event, stateName, errorType) => {
    this.setState({[stateName]: event.target.value, [errorType]: false});
  };
  onPhoneNumber = (value) => {
    this.setState({
      customerPhoneNumber: value.replace(/([^0-9])/g, ""),
      customerPhoneNumberHasError: false
    });
  };

  addNewMeter = () => {
    console.log("clicked");
    // const {state} = this;
    // this.setState({imeiHasAlreadyTaken: false});
    // const data = {
    //   account_number: state.accountNumber,
    //   users_ids: state.selectedUsers.map(i => i.id),
    //   imei: state.imeiNumber,
    //   contact_full_name: state.customerName,
    //   contact_address: state.customerAddress,
    //   contact_phone: state.customerPhoneNumber,
    //   model: null,
    //   contact_region_id: null,
    //   contact_district_id: null
    // };
    // let isError = false;
    // for (let key in data) {
    //   if (key === "account_number") {
    //     if (data[key].length === 0) {
    //       isError = true;
    //       this.setState({accountNumberHasError: true});
    //     }
    //   }
    //   if (key === "users_ids") {
    //     if (data[key].length === 0) {
    //       isError = true;
    //       this.setState({selectedUsersHasError: true})
    //     }
    //   }
    //   if (key === "imei") {
    //     if (data[key].length === 0) {
    //       isError = true;
    //       this.setState({
    //         imeiNumberHasError: true,
    //         imeiHasNotExist: false,
    //         imeiHasAlreadyTaken: false
    //       });
    //     }
    //   }
    //   if (key === "contact_phone") {
    //     if (data[key].length < 12) {
    //       isError = true;
    //       this.setState({customerPhoneNumberHasError: true});
    //     }
    //   }
    //   if (key === "contact_address") {
    //     if (data[key].length === 0) {
    //       isError = true;
    //       this.setState({customerAddressHasError: true});
    //     }
    //   }
    //   if (key === "contact_full_name") {
    //     if (data[key].length === 0) {
    //       isError = true;
    //       this.setState({customerNameHasError: true});
    //     }
    //   }
    // }
    //
    // if (!isError) {
    //   new Api(this.props.store)
    //     .createMeter(JSON.stringify(data))
    //     .then(({data}) => {
    //       if (data.ok) {
    //         this.props.history.push("/tableList");
    //       } else if (data.error.code === 4) {
    //         this.setState({imeiHasNotExist: true, imeiHasAlreadyTaken: false});
    //       } else if (data.error.code === 6) {
    //         this.setState({imeiHasAlreadyTaken: true, imeiHasNotExist: false});
    //       }
    //     });
    // }
  };

  render() {
    const {handleInputValue, addNewMeter} = this;
    const {
      accountNumber, customerName, customerAddress, accountNumberHasError, customerAddressHasError,
      customerNameHasError} = this.state;

    return (
      <Layout>
        <Link to="/tableList">
          <Button className={`${buttonStyle.btnXs} ${buttonStyle.btnBlue}`}>
            {Strings.buttons.back}</Button>
        </Link>

        <div className={Styles.addingItemPage}>

          <div className={Styles.item}>
            <span>{Strings.inputTitles.accountNumber}</span>
            <input type="text"
                   placeholder={Strings.inputPlaceholders.accountNumber}
                   value={accountNumber}
                   onChange={(event) => handleInputValue(event, "accountNumber", "accountNumberHasError")}
            />
            {accountNumberHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}
          </div>
          <div className={Styles.item}>
            <span>{Strings.inputTitles.customerAddress}</span>
            <input type="text"
                   placeholder={Strings.inputPlaceholders.customerAddress}
                   value={customerAddress}
                   onChange={(event) => handleInputValue(event, "customerAddress", "customerAddressHasError")}
            />
            {customerAddressHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}
          </div>
          <div className={Styles.item}>
            <span>{Strings.inputTitles.userName}</span>
            <input type="text"
                   placeholder={Strings.inputPlaceholders.userFullName}
                   value={customerName}
                   onChange={(event) => handleInputValue(event, "customerName", "customerNameHasError")}
            />
            {customerNameHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}
          </div>

          <div className={Styles.item}>
            <span>{Strings.inputTitles.volume}</span>
            <input type="text"
                   placeholder={Strings.inputPlaceholders.volume}
                   // value={customerName}
                   // onChange={() => {}}
            />
            {/*{customerNameHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}*/}
          </div>
          <div className={Styles.item}>
            <span>{Strings.inputTitles.installationDepth}</span>
            <input type="text"
                   placeholder={Strings.inputPlaceholders.installationDepth}
                   // value={customerName}
                   // onChange={() => {}}
            />
            {/*{customerNameHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}*/}
          </div>
          <div className={Styles.item}>
            <span>{Strings.inputTitles.systemTime}</span>
            <input type="text"
                   placeholder={Strings.inputPlaceholders.systemTime}
                   // value={customerName}
                   // onChange={() => {}}
            />
            {/*{customerNameHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}*/}
          </div>
          <div className={Styles.item}>
            <span>{Strings.inputTitles.serverSimCardNumber}</span>
            <input type="text"
                   placeholder={Strings.inputPlaceholders.serverSimCardNumber}
                   // value={customerName}
                   // onChange={() => {}}
            />
            {/*{customerNameHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}*/}
          </div>
          <div className={Styles.item}>
            <span>{Strings.inputTitles.serverSimCardReserve}</span>
            <input type="text"
                   placeholder={Strings.inputPlaceholders.serverSimCardReserve}
                   // value={customerName}
                   // onChange={() => {}}
            />
            {/*{customerNameHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}*/}
          </div>

          {/*<div className={Styles.item}>*/}
          {/*  <span>{Strings.inputTitles.imeiNumber}</span>*/}
          {/*  <input type="number"*/}
          {/*         placeholder={Strings.inputPlaceholders.imeiNumber}*/}
          {/*         value={imeiNumber}*/}
          {/*         onChange={(event) => handleInputValue(event, "imeiNumber", "imeiNumberHasError")}*/}
          {/*  />*/}
          {/*  {imeiNumberHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}*/}
          {/*  {imeiHasAlreadyTaken && <span className={Styles.hasError}>{Strings.errors.imeiHasAlreadyTaken}</span>}*/}
          {/*  {imeiHasNotExist && <span className={Styles.hasError}>{Strings.errors.imeiHasNotExist}</span>}*/}
          {/*</div>*/}

          {/*<div className={Styles.select}>*/}
          {/*  <span>{Strings.inputTitles.users}</span>*/}
          {/*  <DropdownSelect*/}
          {/*    options={usersOptions}*/}
          {/*    value={selectedUsers}*/}
          {/*    onChange={onSelectUser}/>*/}
          {/*  {selectedUsersHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}*/}
          {/*</div>*/}

          <div className={Styles.item}>
            <Button className={`${buttonStyle.btnMax} ${buttonStyle.btnBlue}`}
                    onClick={addNewMeter}>
              {Strings.buttons.add}
            </Button>
          </div>
        </div>

      </Layout>
    )
  }
}


const mapStateToProps = (store) => {
  return {store};
};

export default connect(mapStateToProps)(AddingNewMeter);