import React, {Component} from "react";
import Styles from './Popup.module.scss';

class Popup extends Component {

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.closePopup();
    }
  }

  render() {
    const {title, children, closePopup, className} = this.props;
    return (
        <div className={Styles.popup}>
          <div className={Styles.popupOverlay}>
            <div className={`${Styles.popupContent} ${className}`} ref={this.wrapperRef}>
              <div className={Styles.popupContentHeader}>
                <p>{title}</p>
                <span className={Styles.popupClose} onClick={closePopup}>x</span>
              </div>
              <div>
                {children}
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default Popup;