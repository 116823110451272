import {CLEAR_STORE, ON_SIGN, RESULT_OF_SEARCH, UPDATE_METER, UPDATE_MODERATOR, UPDATE_USER} from "../ActionTypes";

export default {
  onSign: (token, role, first_name, last_name) => ({type: ON_SIGN, token, role, first_name, last_name}),
  updateModerator: (moderator) => ({type: UPDATE_MODERATOR, moderator}),
  updateUser: (user) => ({type: UPDATE_USER, user}),
  updateMeter: (meter) => ({type: UPDATE_METER, meter}),
  clearStore: () => ({type: CLEAR_STORE}),
  isFoundElem: (bool) => ({type: RESULT_OF_SEARCH, bool}),

}
