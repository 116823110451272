import {
  ON_SIGN,
  UPDATE_MODERATOR,
  UPDATE_USER,
  CLEAR_STORE,
  UPDATE_METER,
  RESULT_OF_SEARCH
} from "../ActionTypes";

const user = JSON.parse(localStorage.getItem("User"));

const updModerator = JSON.parse(localStorage.getItem("updateModerator"));
const updUser = JSON.parse(localStorage.getItem("updateUser"));
const updMeter = JSON.parse((localStorage.getItem("updateMeter")));

const initialState = {
  firstName: user !== null ? user.firstName : null,
  lastName: user !== null ? user.lastName : null,
  userRole: user !== null ? user.userRole : null,
  access_token: user !== null ? user.access_token : null,
  updateModerator: updModerator !== null ? updModerator : null,
  updateUser: updUser !== null ? updUser : null,
  updateMeter: updMeter !== null ? updMeter : null,
  isFoundElem: false
};

const onSign = (state, token, role, first_name, last_name) => {
  const user = JSON.stringify({access_token: token, userRole: role, firstName: first_name, lastName: last_name});
  localStorage.setItem("User", user);
  return {...state, access_token: token, userRole: role, firstName: first_name, lastName: last_name};
}

const updateModerator = (state, moderator) => {
  localStorage.setItem("updateModerator", JSON.stringify(moderator));
  return {...state, updateModerator: moderator};
};

const updateUser = (state, user) => {
  localStorage.setItem("updateUser", JSON.stringify(user));
  return {...state, updateUser: user};
};

const updateMeter = (state, meter) => {
  localStorage.setItem("updateMeter", JSON.stringify(meter));
  return {...state, updateMeter: meter};
};

const clearStore = (state) => {
  return {
    ...state, userRole: null,
    access_token: null, updateModerator: null, updateUser: null
  }
};

const isFoundElem = (state, bool) => {
  return {...state, isFoundElem: bool}
};

const store = (state = initialState, action) => {
  switch (action.type) {
    case ON_SIGN:
      return onSign(state, action.token, action.role, action.first_name, action.last_name);

    case UPDATE_MODERATOR:
      return updateModerator(state, action.moderator);

    case UPDATE_USER:
      return updateUser(state, action.user);

    case CLEAR_STORE:
      return clearStore(state);

    case UPDATE_METER:
      return updateMeter(state, action.meter);

    case RESULT_OF_SEARCH:
      return isFoundElem(state, action.bool)

    default:
      return state;
  }
};

export default store;