import React from 'react';
import ReactDOM from 'react-dom';
import App from "./Components/App";
import {Provider} from "react-redux";
import {createStore} from "redux";
import reducers from "./Store/Reducers/Store";

const store = createStore(reducers);

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById("root")
);
