import React, {Component} from "react";
import Layout from "../Layout/Layout";
import {connect} from "react-redux";
import Styles from "./Users.module.scss";
import Strings from "../../Utils/Strings";
import Button from "../../Components/Button";
import buttonStyle from "../../Components/Button/Button.module.scss";
import deleteIcon from "../../Resource/waste.png";
import Api from "../../Api/Api";
import {PAGE_NUMBER, PAGE_SIZE} from "../../Utils/Constants";
import InputPhone from "../../Components/InputPhone/InputPhone";
import Popup from "../../Components/Popup";
import PopupStyle from "../../Components/Popup/Popup.module.scss";
import Loader from "../../Components/Loader/Loader";
import Pagination from "../../Components/Pagination/Pagination";

class Users extends Component {

  state = {
    users: [],
    isLoader: false,
    isPopupConfirm: false,
    deleteItemId: null,

    username: "",
    usernameHasError: false,
    usernameAlreadyTaken: false,

    firstName: "",
    firstNameHasError: false,

    lastName: "",
    lastNameHasError: false,

    phone: "",
    phoneHasError: false,

    password: "",
    passwordHasError: false
  };

  componentDidMount() {
    this.gettingUsersList();
  };
  gettingUsersList = (value) => {
    const pageNumber = value || PAGE_NUMBER;
    this.setState({isLoader: true, users: []});
    new Api(this.props.store)
      .usersList(pageNumber, PAGE_SIZE)
      .then(({data}) => {
        const {page, offset, totalCount} = data.data.pagination;
        this.setState({
          isLoader: false,
          users: data.data.users,
          pageCount: Math.ceil(totalCount / offset),
          forcePage: page - 1,
        });
      });
  };

  handleInputValue = (event, stateName, errorType) => {
    this.setState({[stateName]: event.target.value, [errorType]: false});
  };
  onPhoneNumber = (value) => this.setState({phone: value.replace(/([^0-9])/g, ""), phoneHasError: false});
  togglePopupConfirm = (item) => this.setState(state => ({
    isPopupConfirm: !state.isPopupConfirm,
    deleteItemId: item ? item.id : null
  }));
  handlePageClick = (data) => {
    const pageNumber = data.selected + 1;
    this.gettingUsersList(pageNumber);
  };

  deleteItem = () => {
    this.setState({isLoader: true, isPopupConfirm: false, users: []});
    new Api(this.props.store)
      .deleteUser(this.state.deleteItemId)
      .then(() => {
        this.gettingUsersList();
      });
  };
  addUser = () => {
    const {username, firstName, lastName, password, phone} = this.state;
    const data = {
      username: username,
      first_name: firstName,
      last_name: lastName,
      password: password,
      phone_number: phone,
      is_active: true,
      region_id: null,
      district_id: null,
      address: null,
      moderators_ids: null,
    };
    let isError = false;

    for (let key in data) {
      if (key === "username") {
        if (data[key].length === 0) {
          isError = true;
          this.setState({usernameHasError: true, usernameAlreadyTaken: false});
        }
      }
      if (key === "first_name") {
        if (data[key].length === 0) {
          isError = true;
          this.setState({firstNameHasError: true});
        }
      }
      if (key === "last_name") {
        if (data[key].length === 0) {
          isError = true;
          this.setState({lastNameHasError: true});
        }
      }
      if (key === "phone_number") {
        if (data[key].length < 12) {
          isError = true;
          this.setState({phoneHasError: true});
        }
      }

      if (key === "password") {
        if (data[key].length === 0) {
          isError = true;
          this.setState({passwordHasError: true});
        }
      }
    }
    this.setState({isLoader: true, usernameAlreadyTaken: false});

    if (!isError) {
      new Api(this.props.store)
        .creatingUser(JSON.stringify(data))
        .then(({data}) => {
          if (data.ok) {
            this.gettingUsersList();
            this.setState({username: "", firstName: "", lastName: "", password: "", phone: ""});
          } else if (data.error.code === 3) {
            this.setState({usernameAlreadyTaken: true, usernameHasError: false, isLoader: false});
          }
        });
    }
  };

  UserInfo = () => {
    const {togglePopupConfirm} = this;
    const {users, forcePage} = this.state;
    return users.map((item, index) => {
      return (
        <tbody key={item.id}>
        <tr>
          <td>{(forcePage * PAGE_SIZE) + (index + 1)}</td>
          <td><img src={deleteIcon} alt="" className={`${Styles.icons} icon`}
                   onClick={() => togglePopupConfirm(item)}/></td>
          <td>{item.username}</td>
          <td>{item.first_name}</td>
          <td>{item.last_name}</td>
          <td>{item.phone_number ? "+" + item.phone_number : null}</td>
        </tr>
        </tbody>
      )
    });
  };
  PopupConfirm = () => {
    const {togglePopupConfirm, deleteItem} = this;
    return (
      <Popup title={Strings.popup.popupHeaderTitles.delete} closePopup={togglePopupConfirm}
             className={PopupStyle.signIn}>

        <div className={Styles.content}>
          <p>{Strings.popup.deleteMeter}</p>
        </div>

        <div className={Styles.confirmPopup}>
          <Button className={` ${buttonStyle.btnBlue}`} onClick={deleteItem}>
            {Strings.buttons.confirm}
          </Button>
          <Button className={` ${buttonStyle.btnGray}`} onClick={togglePopupConfirm}>
            {Strings.buttons.refuse}
          </Button>
        </div>
      </Popup>
    )
  };

  render() {
    const {handleInputValue, UserInfo, addUser, onPhoneNumber, PopupConfirm, handlePageClick} = this;
    const {
      username, usernameHasError, usernameAlreadyTaken, firstName, firstNameHasError, lastName,
      lastNameHasError, passwordHasError, password, phone, phoneHasError, isPopupConfirm,
      isLoader, pageCount, forcePage} = this.state;

    return (
      <Layout>
        {isPopupConfirm && <PopupConfirm/>}

        <div className={Styles.addingItemPage}>
          <div className={Styles.item}>
            <span>{Strings.inputTitles.username}</span>
            <input type="text"
                   placeholder={Strings.inputPlaceholders.username}
                   value={username}
                   onChange={
                     (e) => handleInputValue(e, "username", "usernameHasError")
                   }
            />
            {usernameHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}
            {usernameAlreadyTaken && <span className={Styles.hasError}>{Strings.errors.usernameHasAlreadyTaken}</span>}
          </div>
          <div className={Styles.item}>
            <span>{Strings.inputTitles.firstName}</span>
            <input type="text"
                   placeholder={Strings.inputPlaceholders.firstName}
                   value={firstName}
                   onChange={(e) => handleInputValue(e, "firstName", "firstNameHasError")}
            />
            {firstNameHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}
          </div>
          <div className={Styles.item}>
            <span>{Strings.inputTitles.lastName}</span>
            <input type="text"
                   placeholder={Strings.inputPlaceholders.lastName}
                   value={lastName}
                   onChange={(e) => handleInputValue(e, "lastName", "lastNameHasError")}
            />
            {lastNameHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}
          </div>
          <div className={Styles.item}>
            <InputPhone title={Strings.inputTitles.phone}
                        value={phone}
                        onChange={onPhoneNumber}
                        placeholder={Strings.inputPlaceholders.phone}
                        hasError={phoneHasError}
                        errorText={Strings.errors.fieldIsEmpty}
            />
          </div>
          <div className={Styles.item}>
            <span>{Strings.inputTitles.password}</span>
            <input type="password"
                   placeholder={Strings.inputPlaceholders.password}
                   value={password}
                   onChange={(e) => handleInputValue(e, "password", "passwordHasError")}
            />
            {passwordHasError && <span className={Styles.hasError}>{Strings.errors.fieldIsEmpty}</span>}

          </div>
          <div className={Styles.item}>
            <Button className={`${buttonStyle.btnMax} ${buttonStyle.btnBlue}`}
                    onClick={addUser}>
              {Strings.buttons.add}
            </Button>
          </div>
        </div>
        <div className={Styles.table}>
          <table>
            <thead>
            <tr>
              <th>#</th>
              <th/>
              <th>{Strings.table.tableHeader.userName}</th>
              <th>{Strings.table.tableHeader.firstName}</th>
              <th>{Strings.table.tableHeader.lastName}</th>
              <th>{Strings.table.tableHeader.phoneNumber}</th>
            </tr>
            </thead>
            <UserInfo/>
          </table>
        </div>

        <Loader isLoader={isLoader}/>

        <Pagination pageCount={pageCount}
                    handlePageClick={handlePageClick}
                    forcePage={forcePage}/>
      </Layout>
    )
  }
}

const mapStateToProps = (store) => {
  return {store}
};

export default connect(mapStateToProps)(Users);