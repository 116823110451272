import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import moment from "moment";

import actions from "../../Store/Actions/Actions";

import Api from "../../Api/Api";

import Layout from "../Layout/Layout";
import Button from "../../Components/Button";
import Popup from "../../Components/Popup";
import Loader from "../../Components/Loader/Loader";
import CheckingUserRole from "../../Utils/CheckingUserRole";
import {METER_DATA_PAGE_NUMBER, PAGE_NUMBER, PAGE_SIZE, USER_ROLE_MODERATOR} from "../../Utils/Constants";

import Strings from "../../Utils/Strings";
import Styles from './TableList.module.scss';
import PopupStyle from "../../Components/Popup/Popup.module.scss";
import buttonStyle from "../../Components/Button/Button.module.scss";

import editIcon from '../../Resource/edit.png';
import deleteIcon from '../../Resource/waste.png';
import Pagination from "../../Components/Pagination/Pagination";

class TableList extends Component {

  state = {
    metersList: [],
    isLoader: true,
    pageCount: null,
    forcePage: null,
    isPopupConfirm: false,
    deleteItemId: null,

    searchByImei: "",
    searchBySerialNumber: "",
    notFound: false,
    isFoundAnyItem: false
  };

  componentDidMount() {
    if (this.props.store.access_token) this.gettingData();
  };

  // AdminContent = () => {
  //   return (
  //     <div className={Styles.hiddenContent}>
  //       <Link to='/tableList/adding'>
  //         <Button className={`${buttonStyle.btnSm} ${buttonStyle.btnBlue}`}>
  //           {Strings.buttons.add}
  //         </Button>
  //       </Link>
  //     </div>
  //   )
  // };

  gettingData = (value) => {
    this.setState({isLoader: true, metersList: []});
    const pageNumber = value || METER_DATA_PAGE_NUMBER;
    if(this.state.isFoundAnyItem) {
      const {searchByImei, searchBySerialNumber} = this.state;
      new Api(this.props.store)
        .filterMeterList(pageNumber, PAGE_SIZE, searchByImei, searchBySerialNumber)
        .then(({data}) => {
          if (data.data.meters.length > 0) {
            const {page, offset, totalCount} = data.data.pagination;
            this.setState({
              isLoader: false,
              metersList: data.data.meters,
              pageCount: Math.ceil(totalCount / offset),
              forcePage: page - 1,
              isFoundAnyItem: true
            });
          }
          if (data.data.meters.length === 0) this.setState({notFound: true});
        });
    } else {
      new Api(this.props.store)
        .metersList(pageNumber, PAGE_SIZE)
        .then(({data}) => {
          const {page, offset, totalCount} = data.data.pagination;
          this.setState({
            isLoader: false,
            metersList: data.data.meters,
            pageCount: Math.ceil(totalCount / offset),
            forcePage: page - 1,
          });
        });
    }
  };

  onItem = (event, elem) => {
    if (elem.serial_number) {
      if (!event.target.classList.contains(Styles.icons)) {
        this.props.history.push(`/tableList/meter/${elem.id}`);
      }
    } else if (!event.target.classList.contains(Styles.icons)) {
      this.setState({isMeterEmpty: true});
    }
  };
  editItem = (item) => {
    this.props.updateMeter(item);
    this.props.history.push("/tableList/edit");
  };
  deleteItem = () => {
    this.setState({isPopupConfirm: false, metersList: [], isLoader: true});
    new Api(this.props.store)
      .deleteMeter(this.state.deleteItemId)
      .then(() => {
        this.gettingData();
      });
  };

  MeterDates = () => {
    const {editItem} = this;
    const {metersList, forcePage} = this.state;
    console.log(metersList);
    return metersList.map((item, index) => {
      return (
        <tbody key={index} onClick={(event) => this.onItem(event, item, index)}>
        <tr>
        
          <td>{(forcePage * PAGE_SIZE) + (index + 1)}</td>
          {CheckingUserRole(this.props.store.userRole, USER_ROLE_MODERATOR) &&
          <td>
            <img src={editIcon} alt="" className={`${Styles.icons} icon`}
                 onClick={() => editItem(item)}/>
          </td>}
          <td>{item.imei}</td>
          <td>{item.model}</td>
          <td>{item.serial_number}</td>
          <td>{item.last_volume_date && moment.unix(item.last_volume_date).format("DD.MM.YYYY HH:mm")}</td>
          <td>{item.volume}</td>
          <td>{item.volume_2}</td>
          <td>{item.ec}</td>
          {item.temperature ? <td>{item.temperature}&#176;C</td> : <td/>}
          <td>{item.meter_battery ? item.meter_battery + "V" : null}</td>
          <td>{item.signal_level ? item.signal_level + "dB" : null}</td>
          <td>{item.last_seen_at && moment.unix(item.last_seen_at).format("DD.MM.YYYY HH:mm")}</td>
        </tr>
        </tbody>
      )
    });
  };

  closePopup = () => this.setState({isMeterEmpty: false});
  PopupComponent = () => {
    const {isMeterEmpty} = this.state;
    const {closePopup} = this;
    if (isMeterEmpty) {
      return (
        <Popup title={Strings.popup.popupHeaderTitles.infoIsNotAvailable} closePopup={closePopup}
               className={PopupStyle.signIn}>

          <div className={Styles.content}>
            <p>{Strings.popup.meterInfoIsNotAvailable}</p>
          </div>
          <Button className={` ${buttonStyle.btnGray}`} onClick={closePopup}>
            {Strings.buttons.close}
          </Button>
        </Popup>
      )
    }
    return null;
  };
  togglePopupConfirm = (item) => this.setState(state => ({
    isPopupConfirm: !state.isPopupConfirm,
    deleteItemId: item ? item.id : null
  }));
  PopupConfirm = () => {
    const {togglePopupConfirm, deleteItem} = this;
    return (
      <Popup title={Strings.popup.popupHeaderTitles.delete} closePopup={togglePopupConfirm}
             className={PopupStyle.signIn}>

        <div className={Styles.content}>
          <p>{Strings.popup.deleteMeter}</p>
        </div>

        <div className={Styles.confirmPopup}>
          <Button className={` ${buttonStyle.btnBlue}`} onClick={deleteItem}>
            {Strings.buttons.confirm}
          </Button>
          <Button className={` ${buttonStyle.btnGray}`} onClick={togglePopupConfirm}>
            {Strings.buttons.refuse}
          </Button>
        </div>
      </Popup>
    )
  };

  handlePageClick = (data) => {
    const pageNumber = data.selected + 1;
    this.gettingData(pageNumber);
  };

  handleOnInput = (value, propType) => this.setState({[propType]: value.trim()});
  closeNotFound = () => this.setState({notFound: false});

  onSearch = () => {
    const {searchByImei, searchBySerialNumber} = this.state;

    if (searchByImei.length > 0 || searchBySerialNumber.length > 0) {
      this.setState({isLoader: true, metersList: [], notFound: false});
      new Api(this.props.store)
        .filterMeterList(PAGE_NUMBER, PAGE_SIZE, searchByImei, searchBySerialNumber)
        .then(({data}) => {

          if (data.data.meters.length > 0) {
            const {page, offset, totalCount} = data.data.pagination;
            this.setState({
              isLoader: false,
              metersList: data.data.meters,
              pageCount: Math.ceil(totalCount / offset),
              forcePage: page - 1,
              isFoundAnyItem: true
            });
          }
          if (data.data.meters.length === 0) {
            this.setState({
              notFound: true,
              isLoader: false,
              pageCount: 0,
              isFoundAnyItem: true
            });
          }
        });
    }
  };
  onResetSearchResult = () => {
    this.setState({
      isFoundAnyItem: false,
      notFound: false,
      searchByImei: "",
      searchBySerialNumber: ""
    }, () => {
      this.gettingData();
    });
  };

  render() {
    const {
      MeterDates, PopupComponent, handlePageClick, PopupConfirm, onSearch,
      handleOnInput, closeNotFound, onResetSearchResult
    } = this;
    const {isLoader, pageCount, forcePage, isPopupConfirm,
      searchBySerialNumber, notFound, isFoundAnyItem} = this.state;

    return (
      <Layout>
        {/*{CheckingUserRole(this.props.store.userRole, USER_ROLE_MODERATOR) && <AdminContent/>}*/}
        {isPopupConfirm && <PopupConfirm/>}
        <PopupComponent/>

        <div className={Styles.filterContent}>
          <input type="text"
                 placeholder={Strings.inputPlaceholders.searchBySerialNumber}
                 value={searchBySerialNumber}
                 onChange={(e) => handleOnInput(e.target.value, "searchBySerialNumber")}
          />

          { isFoundAnyItem && <Button className={` ${buttonStyle.btnGray} ${buttonStyle.btnXs}`} onClick={onResetSearchResult}>
            {Strings.buttons.reset}</Button>}

          <Button className={` ${buttonStyle.btnBlue} ${buttonStyle.btnSm}`} onClick={onSearch}>
            {Strings.buttons.search}</Button>
        </div>

        {notFound && <div className={Styles.notFound}>
          <span>{Strings.errors.searchResultNotFound}</span>
          <span className={Styles.notFoundClose} onClick={closeNotFound} />
        </div>}

        {isFoundAnyItem && <p className={Styles.resultOfSearch}>{Strings.resultOfSearch}</p>}

        <div className={Styles.table}>
          <table>
            <thead>
            <tr>
              <th>#</th>
              {CheckingUserRole(this.props.store.userRole, USER_ROLE_MODERATOR) && <th/>}
              <th>{Strings.table.tableHeader.imei}</th>
              <th>{Strings.table.tableHeader.customerFullName}</th>
              <th>{Strings.table.tableHeader.serialNumber}</th>
              <th>{Strings.table.tableHeader.lastVolumeDate}</th>
              <th>{Strings.table.tableHeader.volume}</th>
              <th>{Strings.table.tableHeader.volumeSecond}</th>
              <th>{Strings.table.tableHeader.ec}</th>
              <th>{Strings.table.tableHeader.temperature}</th>
              <th>{Strings.table.tableHeader.modemBattery}</th>
              <th>{Strings.table.tableHeader.signalLevel}</th>
              <th>{Strings.table.tableHeader.lastSeenAt}</th>
            </tr>
            </thead>
            <MeterDates/>
          </table>
        </div>

        <Loader isLoader={isLoader}/>

        <Pagination pageCount={pageCount}
                    handlePageClick={handlePageClick}
                    forcePage={forcePage}/>
      </Layout>
    )
  }
}

const mapStateToProps = (store) => {
  return {store}
};

const mapDispatchToProps = () => (dispatch) => {
  return {
    updateMeter: (meter) => dispatch(actions.updateMeter(meter)),
    isFoundElem: (bool) => dispatch(actions.isFoundElem(bool))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableList);